function apply(el, { value: options }) {
  el.classList.add('form-control');

  if (options?.invalid === true) {
    el.classList.add('form-control-invalid');
  } else {
    el.classList.remove('form-control-invalid');
  }

  if (options?.focused === true) {
    el.classList.add('form-control-focused');
  } else {
    el.classList.remove('form-control-focused');
  }

  if (options?.disabled === true) {
    el.classList.add('form-control-disabled');
  } else {
    el.classList.remove('form-control-disabled');
  }

  if (el.tagName === 'SELECT') {
    // Sometimes `selectedIndex` is incorrect unless `requestAnimationFrame` is used.
    requestAnimationFrame(() => {
      const selectionOptionEl = el.options[el.selectedIndex];

      const isPlaceholderOptionSelected =
        selectionOptionEl &&
        selectionOptionEl.disabled &&
        [null, ''].includes(selectionOptionEl._value);

      if (isPlaceholderOptionSelected) {
        el.classList.add('form-control-placeholder-option-selected');
      } else {
        el.classList.remove('form-control-placeholder-option-selected');
      }
    });
  }
}

export default {
  mounted: apply,
  updated: apply,
};
